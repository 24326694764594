import { useEffect } from "react";
import AOS from "aos";
import "./Calture.css";
import { Container, Row, Col } from "react-bootstrap";
import calture1 from "../../assets/images/calture1.png"; 
import {
  FaConnectdevelop,
  FaJsfiddle,
  FaMedapps,
} from "react-icons/fa";
import secure from "../../assets/images/home/secure.png";
import more from "../../assets/images/home/more.png";
import event from "../../assets/images/home/event.png";
import food from "../../assets/images/home/food.png";
import graduation from "../../assets/images/home/graduation.png";
import restaurant from "../../assets/images/home/restaurant.png";
import pumps from "../../assets/images/home/pumps.png";
import demand from "../../assets/images/home/demand.png";
import cart from "../../assets/images/home/cart.png";
import truck from "../../assets/images/home/truck.png";
import healthcare from "../../assets/images/home/healthcare.png";
import icon1 from "../../assets/images/home/icon1.png";
import calture from "../../assets/images/calture.png";



const Calture = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <section className="calture-experience pt-120  texture-bg-2">
        <Container>
          <Row className="g-6 align-items-center justify-content-between pt-120">
            <Col lg={6} data-aos="fade-down" className="order-2 order-lg-1 order-md-1">
              <div className="calture-head">
                <h4 className="display-four ">
                  <span className="tcp-1">Culture at Impetrosys</span>
                </h4>
                <h5>Focus is having the unwavering attention to complete what you set out to do.</h5>
                <p>
                  The sad thing is the majority of people have no clue about
                  what they truly want. They have no clarity. When asked the
                  question, responses will be superficial at best, and at worst,
                  will be what someone else wants for them.
                </p>
              </div>
            </Col>
            <Col lg={6} className="order-1 order-lg-2  order-md-2">
              <div className="caltur mb-5">
                <img src={calture} alt="caltur" className="w-100"/>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="work-category texture-bg pt-120">
        <Container>
          <Row>
            <Col lg={12}>
              <div
                className="common-heading ptag text-center"
                data-aos="fade-down"
              >
                <h4 className="display-four">
                  <strong className="tcp-1">Industries</strong> We Serve
                </h4>

                <p>
                  We are committed to serving a diverse range of industries,
                  providing tailored digital solutions to effectively organize
                  and streamline their operations.
                </p>
              </div>
            </Col>
          </Row>
          <div className="industry-25 mt-4">
            <Row>
              <Col lg={3} md={4} sm={6} xs={6} data-aos="fade-up">
                <div className="industry-workfor">
                  <img src={secure} alt="img" />
                  <h6>Real estate</h6>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6} data-aos="fade-up">
                <div className="industry-workfor">
                  <img src={graduation} alt="img" />
                  <h6>Education</h6>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6} data-aos="fade-up">
                <div className="industry-workfor">
                  <img src={truck} alt="img" />
                  <h6>Transport</h6>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6} data-aos="fade-up">
                <div className="industry-workfor">
                  <img src={demand} alt="img" />
                  <h6>On-Demand</h6>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6} data-aos="fade-up">
                <div className="industry-workfor">
                  <img src={event} alt="img" />
                  <h6>Event</h6>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6} data-aos="fade-up">
                <div className="industry-workfor">
                  <img src={cart} alt="img" />
                  <h6>eCommerce</h6>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6} data-aos="fade-up">
                <div className="industry-workfor">
                  <img src={healthcare} alt="img" />
                  <h6>Healthcare</h6>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6} data-aos="fade-up">
                <div className="industry-workfor">
                  <img src={restaurant} alt="img" />
                  <h6>Restaurant</h6>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6} data-aos="fade-up">
                <div className="industry-workfor">
                  <img src={icon1} alt="img" />
                  <h6>Finance</h6>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6} data-aos="fade-up">
                <div className="industry-workfor">
                  <img src={food} alt="img" />
                  <h6>Grocery</h6>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6} data-aos="fade-up">
                <div className="industry-workfor">
                  <img src={pumps} alt="img" />
                  <h6>Music & Entertainment </h6>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6} data-aos="fade-up">
                <div className="industry-workfor">
                  <img src={more} alt="img" />
                  <h6>Many More..</h6>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className="calture-section-3 pt-120 pb-120 texture-bg">
        <div className="container">
          <div className="row g-6 justify-content-between">
            <div className="col-lg-6 order-2 order-lg-1  order-md-1">
              <div className="About-us-content">
                <div className="text-center text-lg-start" data-aos="fade-down">
                  <h4 className="display-four">
                    Smart Strategy & Excellent Performance:
                  </h4>
                  <p>
                    Our process ensures high productivity and profitability for
                    your solution.
                  </p>
                </div>
                <div className="our-feature-list mt-5">
                  <ul className="d-grid gap-lg-8 gap-6">
                    <li className="d-flex gap-lg-6 gap-4" data-aos="fade-up">
                      <span className="icon-btn-2 fill fs-three">
                        <i>
                          <FaMedapps />
                        </i>
                      </span>
                      <div className="d-grid gap-2 gap-lg-3">
                        <span className="fs-four fw-medium">
                          Data Analysis:
                        </span>
                        <span className="fw-normal">
                          We conduct comprehensive data collection and analysis
                          to identify your institution’s current strengths and
                          differentiators.
                        </span>
                      </div>
                    </li>
                    <li className="d-flex gap-lg-6 gap-4" data-aos="fade-up">
                      <span className="icon-btn-2 fill fs-three">
                        <i>
                          <FaJsfiddle />
                        </i>
                      </span>
                      <div className="d-grid gap-2 gap-lg-3">
                        <span className="fs-four fw-medium">Planning:</span>
                        <span className="fw-normal">
                          We collaborate with you to develop a plan that is
                          actionable, feasible, and tailored to your needs.
                        </span>
                      </div>
                    </li>
                    <li className="d-flex gap-lg-6 gap-4" data-aos="fade-up">
                      <span className="icon-btn-2 fill fs-three">
                        <i>
                          <FaConnectdevelop />
                        </i>
                      </span>
                      <div className="d-grid gap-2 gap-lg-3">
                        <span className="fs-four fw-medium">
                          Delivering Results:
                        </span>
                        <span className="fw-normal">
                          In this phase, we provide expertise as needed and
                          support select initiatives to achieve your desired
                          outcomes.
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2  order-md-2" data-aos="fade-down">
              <div className="about-us-banner-wrapper d-flex align-items-end justify-content-center gap-6">
                <div className=" mb-4 caltures position-relative">
                  <img
                    className="w-100"
                    src={calture1}
                    alt="about us banner"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Calture;
