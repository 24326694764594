import React, { useEffect } from 'react';
import "./Homeslider.css";
import AOS from "aos";
import { FaArrowRightLong } from "react-icons/fa6";
import FB from "../../../assets/images/FB.png";
import twitter from "../../../assets/images/twitter.png";
import linkedIn from "../../../assets/images/linkedIn.png";
import insta from "../../../assets/images/insta.png";
import google from "../../../assets/images/google.png";
import banner10 from "../../../assets/images/banner10.png";
import banner2 from "../../../assets/images/banner2.png";
import banner3 from "../../../assets/images/banner3.png";
import banner4 from "../../../assets/images/banner4.png";
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const HeroSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section className="hero-section texture-bg pt-120 pb-120">
      <Container>
        <Row>
          <Col lg={8} md={8} className="order-2 order-lg-1 order-md-1 pt-120" data-aos="fade-up">
            <div className="hero-content pb-xl-17 pb-12">
              <h2 className="display-two mb-6">
                Fostering <span className="tcp-1">individual</span> <span className="tcp-4">business</span> needs
              </h2>
              <p className="hero-description">
                Empowering businesses with personalized solutions that cater to their specific goals and challenges. We strive to exceed expectations and foster long-term partnerships built on trust, transparency.
              </p>
              <div className="hero-btn-area d-flex">
                <Link to="/request-a-quote" className="bttn-1">
                  Request a Quote
                  <span className="icon d-center icon-right">
                    <i><FaArrowRightLong /></i>
                  </span>
                </Link>
              </div>

              {/* Social Links */}
              <div className="listen-to-btns">
                <p className="fs-lg mb-6 fw-medium mb-3">Reach out to us with :</p>
                <ul className="d-flex gap-3 mb-0">
                  <li className="brand-icon">
                    <a href="https://www.facebook.com/ImpetrosysSoftwareSolution" target="_blank" rel="noopener noreferrer">
                      <img className="w-100" src={FB} alt="Facebook" />
                    </a>
                  </li>
                  <li className="brand-icon">
                    <a href="https://x.com/IMPETROSYS_IND" target="_blank" rel="noopener noreferrer">
                      <img className="w-100" src={twitter} alt="Twitter" />
                    </a>
                  </li>
                  <li className="brand-icon">
                    <a href="https://www.linkedin.com/company/impetrosys" target="_blank" rel="noopener noreferrer">
                      <img className="w-100" src={linkedIn} alt="LinkedIn" />
                    </a>
                  </li>
                  <li className="brand-icon">
                    <a href="https://www.instagram.com/_impetrosys" target="_blank" rel="noopener noreferrer">
                      <img className="w-100" src={insta} alt="Instagram" />
                    </a>
                  </li>
                  <li className="brand-icon">
                    <a href="https://g.co/kgs/89gVGro" target="_blank" rel="noopener noreferrer">
                      <img className="w-100" src={google} alt="Google+" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>

          <Col lg={4} md={4} className="order-1 order-lg-2  order-md-2">
            <div className="hero-banner ps-xxl-16 aos-init aos-animate" data-aos="zoom-in">
              {/* Banner Images */}
              <div className="banner-items d-center gap-lg-10 gap-sm-8 gap-6">
                <div className="d-grid gap-lg-16 gap-sm-10 gap-6 banner-left-items pb-6">
                  <div className="img-area banner-item-1">
                    <img className="w-100" src={banner10} alt="banner" />
                  </div>
                  <div className="img-area banner-item-3">
                    <img className="w-100" src={banner3} alt="banner" />
                  </div>
                </div>
                <div className="d-grid gap-lg-13 gap-sm-8 gap-5">
                  <div className="img-area banner-item-2">
                    <img className="w-100" src={banner2} alt="banner" />
                  </div>
                  <div className="img-area banner-item-4">
                    <img className="w-100" src={banner4} alt="banner" />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;
