import app6 from '../../assets/images/portfolio/app6.png';
import app6image0 from '../../assets/images/portfolio/app6image0.png';
import app6image1 from '../../assets/images/portfolio/app6image1.png';
import app11 from '../../assets/images/portfolio/app11.png';
import app11image0 from '../../assets/images/portfolio/app11image0.png';
import app11image1 from '../../assets/images/portfolio/app11image1.png';
import app4 from '../../assets/images/portfolio/app4.png';
import app8 from '../../assets/images/portfolio/app8.png';
import app14 from '../../assets/images/portfolio/app14.png';
import app14image0 from '../../assets/images/portfolio/app14image0.png';
import app14image1 from '../../assets/images/portfolio/app14image1.png';
import app14image2 from '../../assets/images/portfolio/app14image2.png';
import app15 from '../../assets/images/portfolio/app15.png';
import app15image0 from '../../assets/images/portfolio/app15image0.png';
import app15image1 from '../../assets/images/portfolio/app15image1.png';
import app15image2 from '../../assets/images/portfolio/app15image2.png';
import app16 from '../../assets/images/portfolio/app16.png';
import app16image0 from '../../assets/images/portfolio/app16image0.png';
import app16image1 from '../../assets/images/portfolio/app16image1.png';
import app16image2 from '../../assets/images/portfolio/app16image2.png';
import app17 from '../../assets/images/portfolio/app17.png';
import app17image0 from '../../assets/images/portfolio/app17image0.png';
import app17image1 from '../../assets/images/portfolio/app17image1.png';
import app17image2 from '../../assets/images/portfolio/app17image2.png';
import app17image3 from '../../assets/images/portfolio/app17image3.png';
import app18 from '../../assets/images/portfolio/app18.png';
import app18image0 from '../../assets/images/portfolio/app18image0.png';
import app18image1 from '../../assets/images/portfolio/app18image1.png';
import app18image2 from '../../assets/images/portfolio/app18image2.png';
import app18image3 from '../../assets/images/portfolio/app18image3.png';
import app18image4 from '../../assets/images/portfolio/app18image4.png';
import app18image5 from '../../assets/images/portfolio/app18image5.png';
import app18image6 from '../../assets/images/portfolio/app18image6.png';
import app18image7 from '../../assets/images/portfolio/app18image7.png';
import app18image8 from '../../assets/images/portfolio/app18image8.png';
import app19 from '../../assets/images/portfolio/app19.png';
import app19image0 from '../../assets/images/portfolio/app19image0.png';
import app19image1 from '../../assets/images/portfolio/app19image1.png';
import app19image3 from '../../assets/images/portfolio/app19image3.png';
import app20 from '../../assets/images/portfolio/app20.png';
import app20e from '../../assets/images/portfolio/app20e.png';
import app20e1 from '../../assets/images/portfolio/app20e1.png';

import pragati from "../../assets/images/portfolio/pragati/pragati.png";
import pragati0 from "../../assets/images/portfolio/pragati/pragati0.png";
import pragati1 from "../../assets/images/portfolio/pragati/pragati1.png";
import pragati2 from "../../assets/images/portfolio/pragati/pragati2.png";
import pragati3 from "../../assets/images/portfolio/pragati/pragati3.png";
import pragati4 from "../../assets/images/portfolio/pragati/pragati4.png";
import ecommerce1 from '../../assets/images/portfolio/ecommerce/ecommerce1.png';
import ecommerce2 from '../../assets/images/portfolio/ecommerce/ecommerce2.png';
import ecommerce3 from '../../assets/images/portfolio/ecommerce/ecommerce3.png';
import ecommerce from '../../assets/images/portfolio/ecommerce/ecommerce.png';
import website6 from '../../assets/images/portfolio//soundchat/website6.png';
import soundchat from "../../assets/images/portfolio/soundchat/soundchat.png";
import soundchat0 from "../../assets/images/portfolio/soundchat/soundchat0.png";
import soundchat1 from "../../assets/images/portfolio/soundchat/soundchat1.png";
import soundchat2 from "../../assets/images/portfolio/soundchat/soundchat2.png";
import soundchat3 from "../../assets/images/portfolio/soundchat/soundchat3.png";
import transport1 from '../../assets/images/portfolio/transport/transport1.png';
import transport2 from '../../assets/images/portfolio/transport/transport2.png';
import transport3 from '../../assets/images/portfolio/transport/transport3.png';
import ca1 from '../../assets/images/portfolio/ca/ca1.png';
import ca2 from '../../assets/images/portfolio/ca/ca2.png';
import ca3 from '../../assets/images/portfolio/ca/ca3.png';
import ca4 from '../../assets/images/portfolio/ca/ca4.png';
import ca5 from '../../assets/images/portfolio/ca/ca5.png';
import website11 from '../../assets/images/portfolio/website11.png';
import mixradio0 from '../../assets/images/portfolio/mixradio/mixradio0.png';
import mixradio from "../../assets/images/portfolio/mixradio/mixradio.png";
import mixradio1 from "../../assets/images/portfolio/mixradio/mixradio1.png";
import mixradio2 from "../../assets/images/portfolio/mixradio/mixradio2.png";
import mixradio3 from "../../assets/images/portfolio/mixradio/mixradio3.png";

import website13 from '../../assets/images/portfolio/ramed/website13.png';
import ramed1 from '../../assets/images/portfolio/ramed/ramed1.png';
import ramed2 from '../../assets/images/portfolio/ramed/ramed2.png';
import ramed3 from '../../assets/images/portfolio/ramed/ramed3.png';
import ramed4 from '../../assets/images/portfolio/ramed/ramed4.png';
import ramed5 from '../../assets/images/portfolio/ramed/ramed5.png';
import ramed6 from '../../assets/images/portfolio/ramed/ramed6.png';
import krashuved0 from "../../assets/images/portfolio/krashuved/krashuved0.png"
import krashuved from "../../assets/images/portfolio/krashuved/krashuved.png"
import krashuved1 from "../../assets/images/portfolio/krashuved/krashuved1.png"
import krashuved2 from "../../assets/images/portfolio/krashuved/krashuved2.png"
import krashuved3 from "../../assets/images/portfolio/krashuved/krashuved3.png"
import hotel1 from '../../assets/images/portfolio/hotel/hotel1.png';
import hotel2 from '../../assets/images/portfolio/hotel/hotel2.png';
import hotel3 from '../../assets/images/portfolio/hotel/hotel3.png';
import hotel4 from '../../assets/images/portfolio/hotel/hotel4.png';
import taxi1 from '../../assets/images/portfolio/taxi/taxi1.png';
import taxi2 from '../../assets/images/portfolio/taxi/taxi2.png';
import taxi3 from '../../assets/images/portfolio/taxi/taxi3.png';
import taxi4 from '../../assets/images/portfolio/taxi/taxi4.png';
import taxi5 from '../../assets/images/portfolio/taxi/taxi5.png';

import website19 from '../../assets/images/portfolio/website19.png';
import website20 from '../../assets/images/portfolio/website20.png';
const MenuItems = [
    {
        id: 1,
        image: ecommerce,
        name: "eCommerce Websites and Apps (upto 3-5)",
        category: "Website",
        gallery: [ecommerce,ecommerce1,ecommerce2,ecommerce3],
        // description: 'Android App -> iOS App -> Website'
    },
    {
        id: 2,
        image: krashuved0,
        name: "On-Demand Service Websites and Apps (2-3)",
        category: "Website",
        gallery: [krashuved0,krashuved,krashuved1,krashuved2,krashuved3],
    },
    {
        id: 3,
        image: website6,
        name: "SoundChatRadio Website and Apps",
        category: "Website",
        gallery: [website6,soundchat,soundchat0,soundchat1,soundchat2,soundchat3],
  
    },
    {
        id: 4,
        image: website13,
        name: "RamedEventSpace",
        category: "Website",
        gallery: [website13,ramed1,ramed2,ramed3,ramed4,ramed5,ramed6],
  
    },
    {
        id: 5,
        image: mixradio0,
        name: "Global max radio live",
        category: "Website",
        gallery: [mixradio0,mixradio,mixradio1,mixradio2,mixradio3],
     
    },
    {
        id: 6,
        image: transport1,
        name: "Transport management",
        category: "Website",
        gallery: [transport1,transport2,transport3],
       
    },
    {
        id: 7,
        image: ca1,
        name: "CA Consultants Website",
        category: "Website",
        gallery: [ca1,ca2,ca3,ca4,ca5],
   
    },

    {
        id: 8,
        image: pragati,
        name: "NGO Website Pmss",
        category: "Website",
        gallery: [pragati,pragati0,pragati1,pragati2,pragati3,pragati4],
        
    },
    {
        id: 9,
        image: app6,
        name: "Family FM App",
        category: "app",
        gallery: [app6,app6image0,app6image1],
    },


    {
        id: 10,
        image: app8,
        name: "RockVibe App",
        category: "app",
        gallery: [app8],
      
    },
    {
        id: 11,
        image: app4,
        name: "Catholic Church App",
        category: "app",
        gallery: [app4],
      
    },
    {
        id: 12,
        image: app11,
        name: "SoundChatRadio App",
        category: "app",
        gallery: [app11,app11image0,app11image1],
      
    },
    {
        id: 13,
        image: app20e,
        name: "eBooks for kids App ",
        category: "app",
        gallery: [app20e,app20e1,app20],
     
    },
    {
        id: 14,
        image: app14,
        name: "Astrology App",
        category: "app",
        gallery: [app14,app14image0,app14image1,app14image2],
     
    },
     {
        id: 15,
        image: app15,
        name: "Fantasy App",
        category: "app",
        gallery: [app15,app15image0,app15image1,app15image2],
     
    },
    {
        id: 16,
        image: app16,
        name: "Taxi Booking App",
        category: "app",
        gallery: [app16,app16image0,app16image1,app16image2],
    
    },
     {
        id: 17,
        image: app17,
        name: "Hotel Booking App",
        category: "app",
        gallery: [app17,app17image0,app17image1,app17image2,app17image3],
        
    },
    {
        id: 18,
        image: hotel1,
        name: " Hotel Booking Website and Apps",
        category: "Website",
        gallery: [hotel1,hotel2,hotel3,hotel4],
      
    },
     {
        id: 19,
        image: taxi1,
        name: " Taxi Booking Website and Apps",
        category: "Website",
        gallery: [taxi1,taxi2,taxi3,taxi4,taxi5],
    
    },
      {
        id: 20,
        image: app18,
        name: "On-Demand Service Apps",
        category: "app",
        gallery: [app18,app18image0,app18image1,app18image2,app18image3,app18image4,app18image5,app18image6,app18image7,app18image8],
       
    },
     {
        id: 21,
        image: app19,
        name: "eCommerce Apps",
        category: "app",
        gallery: [app19,app19image0,app19image1,app19image3],
       
    },
    {
        id: 22,
        image: website11,
        name: "Inventory and POS",
        category: "Website",
        gallery: [website11],
       
    },
    {
        id: 23,
        image: website19,
        name: "Project management system",
        category: "Website",
        gallery: [website19],
    },
    {
        id: 24,
        image: website20,
        name: "Payroll management System",
        category: "Website",
        gallery: [website20],
      
    },
   
  
    
];



export default MenuItems;