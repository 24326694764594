import React, { useEffect } from "react";
import AOS from "aos";
import "./Lifecycle.css";
import { BsRocketTakeoff } from "react-icons/bs";
import { LuCalendarDays } from "react-icons/lu";
import { LuPlay } from "react-icons/lu";
import record from "../../../assets/images/record.png";
import { Container,Row,Col } from 'react-bootstrap';

const Projects = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <section className="recent-episodes-section pt-120 pb-120 texture-bg-2">
      <Container>
        <Row
          className="g-6 justify-content-between mb-5"
          data-aos="flip-right">
          <Col lg={6}>
            <div className="text-center text-lg-start">
              <span className="subheading-border fw-semibold mb-4 fs-xl">
                <span className="fs-2xl">
                  <i><BsRocketTakeoff /></i>
                </span>
                Recent Milestone
              </span>
              <h4 className="display-four fw-bold">
                Discover Our Recently Completed Projects
              </h4>
            </div>
          </Col>
          <Col lg={5}>
            <div className="ps-xxl-10 text-center text-lg-start">
              <p className="mb-lg-10 mb-sm-6 mb-4">
                Discover our recent successes and see how we’ve brought
                innovative solutions to life. Explore our latest completed
                projects to understand our expertise and impact. Our portfolio
                showcases the diverse range of challenges we’ve tackled and the
                measurable results we’ve achieved. Each project reflects our
                commitment to excellence and our ability to deliver high-quality
                outcomes tailored to our client`s needs.
              </p>
            </div>
          </Col>
        </Row>
        <Row  className="recent-episodes g-6"  data-aos="fade-right" data-aos-duration="2000">
          <Col lg={6} md={6} className="mb-2" >
            <div className="episode-card small-card  bgc-2 rounded">
              <div className="card-top d-between  gap-4 mb-6">
                <div className="d-flex align-items-center gap-4 flex-wrap flex-sm-nowrap">
                  <div className="episode-time d-flex align-items-center gap-2">
                    <span className="tcp-1 fs-xl">
                      <i><LuCalendarDays /></i>
                    </span>
                    <span className="fs-lg fw-medium text-nowrap">
                      Jul 2024
                    </span>
                  </div>
                </div>
                <div className="record-img d-none d-sm-block">
                  <div className="record-img-animation  d-flex height-46">
                    <img className="w-100" src={record} alt="record" />
                    <img className="w-100" src={record} alt="record" />
                  </div>
                </div>
              </div>
              <h3 className="card-title  mb-4 mt-4">
                <span>
                  <span className="tcp-4">BCTPay </span>
                  Empowering Businesses with Payment Solutions.
                </span>
              </h3>
              <p className="fs-sm">
                BCTPay Merchant Solution offers businesses a streamlined
                platform to manage payments efficiently. It enables secure
                transactions, supports various payment methods, and simplifies
                invoice management, all designed to enhance your business
                operations.
              </p>
              <div className="card-btns d-between flex-wrap flex-sm-nowrap gap-6 mt-4">
                <a href="#" className="icon-btn d-center gap-3">
                  <span className="icon fs-xl fw-bold">
                    <i className="play-icon"><LuPlay /></i>
                  </span>
                  <span className="text fw-semibold">Visit Live</span>
                </a>
                <span className="tag-btn">Know More.</span>
              </div>
            </div>
          </Col>
          <Col lg={6} md={6} className="mb-2">
            <div className="episode-card small-card p-xxl-10 p-6 bgc-2 rounded">
              <div className="card-top d-between gap-xxl-6 gap-4 mb-6">
                <div className="d-flex align-items-center gap-4 flex-wrap flex-sm-nowrap">
                  <div className="episode-time d-flex align-items-center gap-2">
                    <span className="tcp-1 fs-xl">
                      <i><LuCalendarDays /></i>
                    </span>
                    <span className="fs-lg fw-medium text-nowrap">
                      Jul 2024
                    </span>
                  </div>
                </div>
                <div className="record-img d-none d-sm-block">
                  <div className="record-img-animation d-flex height-46">
                    <img className="w-100" src={record} alt="record" />
                    <img className="w-100" src={record} alt="record" />
                  </div>
                </div>
              </div>
              <h3 className="card-title mb-4 mt-4">
                <span>
                  <span className="tcp-4">SoundChatRadio </span>#No. 1 Caribbean
                  Radio Station.
                </span>
              </h3>
              <p className="fs-sm">
                Sound Chat Radio is a leading media entity in the New York
                Tri-State Caribbean Diaspora and beyond. The station covers
                socio-cultural and political topics that spark much-needed
                discussions.
              </p>
              <div className="card-btns d-between flex-wrap flex-sm-nowrap gap-6 mt-5">
                <a href="#" className="icon-btn d-center gap-3">
                  <span className="icon fs-xl fw-bold">
                    <i className="play-icon"><LuPlay /></i>
                  </span>
                  <span className="text fw-semibold">Visit Live</span>
                </a>
                <span className="tag-btn">Know More.</span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Projects;