import React from "react";
import "./Service.css";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaLaptopCode } from "react-icons/fa";
import { HiDevicePhoneMobile } from "react-icons/hi2";
import { GiUbisoftSun } from "react-icons/gi";
import { FaMixcloud } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import { FiLayers } from "react-icons/fi";
import { AiOutlineSound } from "react-icons/ai";
import { GoGift } from "react-icons/go";
import { Container, Row, Col } from "react-bootstrap";
import { BsRocketTakeoff } from "react-icons/bs";
import whychoose from "../../assets/images/whychoose.png";
import arrowvector from "../../assets/images/arrowvector.png";
import elevate2 from "../../assets/images/elevate2.png";
import vector2 from "../../assets/images/vector2.png";
const Service = () => {
  return (
    <>
      <section className="service-reason-section pt-120 pb-120 texture-bg-2">
        <Container>
          <Row data-aos="flip-up">
            <Col lg={12} className="pt-120">
              <div className="text-center">
                <h4 className="display-four mb-4 tcp-1">
                  Services
                </h4>
                <h6 className="service-offer" >We Offer Our Dedicated Services Worldwide</h6>
                <p>
                  We are proud to offer our dedicated services worldwide.
                  Serving a global clientele is both a significant
                  responsibility and a challenging endeavor. Customer
                  satisfaction is a testament to the quality and excellence of
                  the services we provide.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="g-6 mt-5">
            <Col lg={3} md={6} sm={6} data-aos="fade-up">
              <div
                className="service-reason-card py-xxl-12 py-sm-6 py-4 px-xxl-8 px-sm-4 px-2 rounded">
                <div className="card-icon-wrapper d-between gap-4 mb-lg-8 mb-4">
                  <div className="card-icon">
                    <span className="fs-two">
                      <i>
                        <FaLaptopCode />
                      </i>
                    </span>
                  </div>
                </div>
                <div className="service-content-area">
                  <h3 className="title fw-bold mb-2">Web Development</h3>
                  <p className="description fw-normal">
                    We make a fresh, functional, and mobile ready website that
                    will help you reach all of your online marketing goals.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={3} md={6} sm={6} data-aos="fade-up">
              <div
                className="service-reason-card py-xxl-12 py-sm-6 py-4 px-xxl-8 px-sm-4 px-2 rounded">
                <div className="card-icon-wrapper d-between gap-4 mb-lg-8 mb-4">
                  <div className="card-icon">
                    <span className="fs-two">
                      <i>
                        <HiDevicePhoneMobile />
                      </i>
                    </span>
                  </div>
                </div>
                <div className="service-content-area">
                  <h3 className="title fw-bold mb-2">Mobile Development</h3>
                  <p className="description fw-normal">
                    We develop feature rich android and ios app that technically
                    advanced and provide a great experience for your customers.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={3} md={6} sm={6} data-aos="fade-up">
              <div
                className="service-reason-card py-xxl-12 py-sm-6 py-4 px-xxl-8 px-sm-4 px-2 rounded">
                <div className="card-icon-wrapper d-between gap-4 mb-lg-8 mb-4">
                  <div className="card-icon">
                    <span className="fs-two">
                      <i>
                        <GiUbisoftSun />
                      </i>
                    </span>
                  </div>
                </div>
                <div className="service-content-area">
                  <h3 className="title fw-bold mb-2">Custom Software</h3>
                  <p className="description fw-normal">
                    We offer a creative, well-designed, web Software for small,
                    medium and large-scale businesses.e-scale businesses.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={3} md={6} sm={6} data-aos="fade-up">
              <div
                className="service-reason-card py-xxl-12 py-sm-6 py-4 px-xxl-8 px-sm-4 px-2 rounded">
                <div className="card-icon-wrapper d-between gap-4 mb-lg-8 mb-4">
                  <div className="card-icon">
                    <span className="fs-two">
                      <i>
                        <FaMixcloud />
                      </i>
                    </span>
                  </div>
                </div>
                <div className="service-content-area">
                  <h3 className="title fw-bold mb-2">Cloud Consulting</h3>
                  <p className="description fw-normal">
                    We offer a quick and easy creation procedure as well as a
                    deep, flexible, and granular custom one.
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="g-6 mt-4 same-service">
            <Col lg={3} md={6} sm={6} data-aos="fade-up">
              <div
                className="service-reason-card py-xxl-12 py-sm-6 py-4 px-xxl-8 px-sm-4 px-2 rounded">
                <div className="card-icon-wrapper d-between gap-4 mb-lg-8 mb-4">
                  <div className="card-icon">
                    <span className="fs-two">
                      <i>
                        <IoSettingsOutline />
                      </i>
                    </span>
                  </div>
                </div>
                <div className="service-content-area">
                  <h3 className="title fw-bold mb-2">DevOps Automation</h3>
                  <p className="description fw-normal">
                    Our Deployment Automation Services include deployment
                    metrics, single tool deployment in all environments
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={3} md={6} sm={6} data-aos="fade-up">
              <div
                className="service-reason-card py-xxl-12 py-sm-6 py-4 px-xxl-8 px-sm-4 px-2 rounded">
                <div className="card-icon-wrapper d-between gap-4 mb-lg-8 mb-4">
                  <div className="card-icon">
                    <span className="fs-two">
                      <i>
                        <AiOutlineSound />
                      </i>
                    </span>
                  </div>
                </div>
                <div className="service-content-area">
                  <h3 className="title fw-bold mb-2">Branding & Promotion</h3>
                  <p className="description fw-normal">
                    With extensive experience in the industry. We design our
                    custom branding services to develop a unique identity of
                    your business.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={3} md={6} sm={6} data-aos="fade-up">
              <div
                className="service-reason-card py-xxl-12 py-sm-6 py-4 px-xxl-8 px-sm-4 px-2 rounded">
                <div className="card-icon-wrapper d-between gap-4 mb-lg-8 mb-4">
                  <div className="card-icon">
                    <span className="fs-two">
                      <i>
                        <FiLayers />
                      </i>
                    </span>
                  </div>
                </div>
                <div className="service-content-area">
                  <h3 className="title fw-bold mb-2">Software Prototyping</h3>
                  <p className="description fw-normal">
                    we optimize the page layout, illustrating structural
                    arrangement and alignments meticulously.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={3} md={6} sm={6} data-aos="fade-up">
              <div
                className="service-reason-card py-xxl-12 py-sm-6 py-4 px-xxl-8 px-sm-4 px-2 rounded">
                <div className="card-icon-wrapper d-between gap-4 mb-lg-8 mb-4">
                  <div className="card-icon">
                    <span className="fs-two">
                      <i>
                        <GoGift />
                      </i>
                    </span>
                  </div>
                </div>
                <div className="service-content-area">
                  <h3 className="title fw-bold mb-2">Prodcut Designing</h3>
                  <p className="description fw-normal">
                    We make a fresh, functional act as a liaison between
                    designers, engineers, help create wireframes and prototypes.
                  </p>
                </div>
              </div>
            </Col>
          </Row>

        </Container>
      </section>

      <section className="elevate-your-experience pt-0 pb-0 texture-bg-2">
        <Container>
          <Row className="g-6 align-items-center justify-content-between">
            <Col lg={7} data-aos="fade-up">
              <div className="text-center text-lg-start">
                <span className="subheading-border fw-medium mb-4 fs-xl">
                  <span className="fs-2xl">
                    <i>
                      <BsRocketTakeoff />
                    </i>
                  </span>
                  IT Support for Business
                </span>
                <h4 className="display-four mb-lg-6 mb-4">
                  Prepare for success with our trusted{" "}
                  <span className="tcp-1"> IT services.</span>
                </h4>
                <p>
                  IT-Solve is the preferred partner for many leading
                  enterprises, SMEs, and technology innovators. We enhance
                  business value through custom software development.
                </p>
              </div>

              <div className="service-hd">
                <Row>
                  <Col md={6} sm={6} xs={6}>
                    <ul>
                      <li>
                        <span>React Js</span>
                      </li>
                      <li>
                        <span>Node Js</span>
                      </li>

                      <li>
                        <span>PHP</span>
                      </li>
                    </ul>
                  </Col>
                  <Col md={6} sm={6} xs={6}>
                    <ul>
                      <li>
                        <span>Android</span>
                      </li>
                      <li>
                        <span>Flutter</span>
                      </li>
                      <li>
                        <span>Ios</span>
                      </li>

                    </ul>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg={5}>
              <div className="elevate-experience-banner d-flex position-relative mt-10 mt-lg-0">
                <div className="banner-area-1">
                  <div className="banner-1 ms-6 ms-sm-0">
                    <img
                      className="w-100 rounded"
                      src={whychoose}
                      alt="Elevate experience 1"
                    />
                  </div>
                  <div className="banner-shape-1">
                    <img
                      className="w-100"
                      src={arrowvector}
                      alt="Arrow shape"
                    />
                  </div>
                </div>
                <div className="banner-area-2">
                  <div className="banner-2">
                    <img
                      className="w-100 rounded"
                      src={elevate2}
                      alt="Elevate experience 2"
                    />
                  </div>
                  <div className="banner-shape-2">
                    <img className="w-100" src={vector2} alt="Arrow shape 2" />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="differentiatorSec" data-aos="fade-up" data-aos-duration="2000">
        <Container>
          <Row>
            <Col md={12}>
              <div className="services-t21-text pb-120">
                <div className="commonTitle">
                  <h4 className="display-four mb-lg-6 mb-2">
                    IMPETROSYS
                    <span className="tcp-1"> Differentiators</span>
                  </h4>
                </div>
                <div className="diffrent">
                  <p>
                    Leveraging our deep technological expertise and strong
                    domain knowledge, we enhance business resiliency through an
                    innovation-driven approach. From development and
                    modernization to the maintenance of high-quality, secure
                    applications, we utilize emerging technologies to help you
                    quickly adapt to new business paradigms. Our robust partner
                    ecosystem and cross-trained, skilled resources enable us to
                    deliver application services with the flexibility, freedom,
                    and support you need. With our expertise and experience, we
                    ensure your applications are future-ready and aligned with
                    your strategic goals.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="product-banner">
        <div className="banner-i">
          <h4>
            Do you have an idea?
          </h4>
          <p>Contact us, and we can turn it into reality for you!</p>
        </div>
        <div className="hero-btn-area d-block d-lg-flex">
          <a className="bttn-1" href="/request-a-quote">Request a Quote
          <span className="icon d-center icon-right">
            <i><FaArrowRightLong /></i></span></a></div>
      </div>
    </>
  );
};

export default Service;
