import React,{ useEffect } from "react";
import "./About.css";
import AOS from "aos";
import { Container, Row, Col } from "react-bootstrap";
import { BsRocketTakeoff } from "react-icons/bs";
import whychoose from "../../assets/images/whychoose.png";
import arrowvector from "../../assets/images/arrowvector.png";
import elevate2 from "../../assets/images/elevate2.png";
import vector2 from "../../assets/images/vector2.png";
import CountUp from "react-countup";
import { FaCrosshairs } from "react-icons/fa6";
import { FaRegLightbulb } from "react-icons/fa";
import { BsFileMedical } from "react-icons/bs";
import ExpertsProfile from "../../Components/Home/Lifecycles/ExpertsProfile";


const About = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);
  return (
    <>
      <section className="elevate-your-experience pt-120 pb-120 texture-bg-2">
        <Container>
          <Row className="g-6 align-items-center justify-content-between">
            <Col lg={7} md={7} sm={12} data-aos="fade-up">
              <div className="text-center text-lg-start abputs-nt">
                <span className="subheading-border fw-medium mb-4 fs-xl">
                  <span className="fs-2xl">
                    <i>
                      <BsRocketTakeoff />
                    </i>
                  </span>
                  About Us
                </span>
                <h4 className="display-four mb-lg-6 mb-4">
                  Who We <span className="tcp-1">Are</span>
                </h4>
                <p>
                  IMPETROSYS established since over a decade with vision to
                  achieve global IT services leadership in providing value-added
                  high quality IT solutions to our clients, with expertise in
                  developing custom web & mobile applications for businesses of
                  all sizes and industries Over the years we have managed to
                  build a solid team of software outsourcing professionals that
                  come from various backgrounds and expand the creative
                  potential of the company.
                </p>
              </div>
            </Col>

            <Col lg={5} md={5} sm={12}>
              <div className="elevate-experience-banner d-flex position-relative mt-10 mt-lg-0">
                <div className="banner-area-1">
                  <div className="banner-1 ms-6 ms-sm-0">
                    <img
                      className="w-100 rounded"
                      src={whychoose}
                      alt="Elevate experience 1"
                    />
                  </div>
                  <div className="banner-shape-1">
                    <img
                      className="w-100"
                      src={arrowvector}
                      alt="Arrow shape"
                    />
                  </div>
                </div>
                <div className="banner-area-2">
                  <div className="banner-2">
                    <img
                      className="w-100 rounded"
                      src={elevate2}
                      alt="Elevate experience 2"
                    />
                  </div>
                  <div className="banner-shape-2">
                    <img className="w-100" src={vector2} alt="Arrow shape 2" />
                  </div>
                </div>

                <div className="year-of-experience bcp-4 rounded">
                  <div className="d-flex gap-4">
                    <div className="odometer-item d-center tcn-900">
                      <CountUp start={1} end={10} delay={0}>
                      </CountUp>
                      <span className="display-four text-dark">+</span>
                    </div>
                    <p className="fs-lg fw-medium tcn-900">
                      Years of experience
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="g-6">
            <Col lg={4} data-aos="zoom-in-up">
              <div className="about-card mb-2
               gap-lg-6 gap-sm-4 gap-2 py-xxl-10 py-lg-8 py-sm-6 py-4 px-xxl-8 px-sm-6 px-4 rounded">
                <span className="fs-two fw-normal icon">
                  <i>
                    <FaCrosshairs />
                  </i>
                </span>
                <div className="about-card-content">
                  <h3 className="contac-title fw-bold mb-lg-3 mb-2">
                    Our Mission
                  </h3>
                  <p className="description">
                    To empower businesses through technology by providing
                    innovative, reliable, and efficient solutions. We strive to
                    exceed expectations and foster long-term partnerships built
                    on trust, transparency.
                  </p>
                </div>
              </div>
            </Col>

            <Col lg={4} data-aos="zoom-in-up">
              <div className="about-card mb-2 gap-lg-6 gap-sm-4 gap-2 py-xxl-10 py-lg-8 py-sm-6 py-4 px-xxl-8 px-sm-6 px-4 rounded">
                <span className="fs-two fw-normal icon">
                  <i>
                    <FaRegLightbulb />
                  </i>
                </span>
                <div className="about-card-content">
                  <h3 className="contac-title fw-bold mb-lg-3 mb-2">
                    Our vision
                  </h3>
                  <p className="description">
                    Our vision We strive to become the world's most admired and
                    valuable business partner and to make every possible move in
                    the upcoming years for our client’s success.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={4} data-aos="zoom-in-down">
              <div className="about-card mb-2 gap-lg-6 gap-sm-4 gap-2 py-xxl-10 py-lg-8 py-sm-6 py-4 px-xxl-8 px-sm-6 px-4 rounded">
                <span className="fs-two fw-normal icon">
                  <i>
                    <BsFileMedical />
                  </i>
                </span>
                <div className="about-card-content">
                  <h3 className="contac-title fw-bold mb-lg-3 mb-2">
                    Our History
                  </h3>
                  <p className="description">
                    IMPETROSYS established since over a decade with vision to
                    achieve global IT services leadership in providing
                    value-added high quality IT solutions to our clients.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <ExpertsProfile/>
      <div className="counter-section pt-120 pb-120 texture-bg-2 position-relative overflow-hidden">
        <Container>
          <Row className="g-6">
            <Col lg={12} data-aos="fade-up">
              <div className="counter-item-head">
                <h6>
                  We prioritize understanding and valuing customer feedback,
                  which is essential in shaping our decisions and strategies.
                </h6>
                <p>
                  We actively seek and incorporate customer feedback to tailor
                  our solutions, ensuring they meet your specific needs and
                  enhance your satisfaction.
                </p>
              </div>
            </Col>
            <Col lg={3} md={3} sm={6} xs={6} data-aos="fade-up">
              <div className="counter-card d-center  
              rounded mt-2">
                <div className="counter-item">
                  <div className="odometer-item d-center">
                    <span
                      className="base display-four fw-bold odometer odometer-auto-theme"
                      data-odometer-final="99.4"
                    >
                      1.5k
                    </span>
                    <span className="display-four fw-bold text">+</span>
                  </div>
                  <p className="descrip fs-lg fw-medium text-center">Project</p>
                  <p>Delivered Successfully</p>
                </div>
              </div>
            </Col>
            <Col lg={3} md={3} sm={6} xs={6} data-aos="fade-up">
              <div className="counter-card d-center  rounded mt-2">
                <div className="counter-item">
                  <div className="odometer-item d-center">
                    <span
                      className="base display-four fw-bold odometer odometer-auto-theme"
                      data-odometer-final="595"
                    >
                      <CountUp start={1} end={100} delay={0}></CountUp>
                    </span>
                    <span className="display-four fw-bold text">+</span>
                  </div>
                  <p className="descrip fs-lg fw-medium text-center">
                    Products
                  </p>
                  <p>We Built so far</p>
                </div>
              </div>
            </Col>
            <Col lg={3} md={3} sm={6} xs={6} data-aos="fade-up">
              <div className="counter-card d-center  rounded  mt-2">
                <div className="counter-item">
                  <div className="odometer-item d-center">
                    <span
                      className="base display-four fw-bold odometer odometer-auto-theme"
                      data-odometer-final="210"
                    >
                      <CountUp start={1} end={10} delay={0}></CountUp>
                    </span>
                    <span className="display-four fw-bold text">+</span>
                  </div>
                  <p className="descrip fs-lg fw-medium text-center">years</p>
                  <p>Of experience</p>
                </div>
              </div>
            </Col>
            <Col lg={3} md={3} sm={6} xs={6} data-aos="fade-up">
              <div className="counter-card d-center rounded mt-2">
                <div className="counter-item">
                  <div className="odometer-item d-center">
                    <span
                      className="base display-four fw-bold odometer odometer-auto-theme"
                      data-odometer-final="72"
                    >
                      <CountUp start={1} end={35} delay={0}></CountUp>
                    </span>
                    <span className="display-four fw-bold text">+</span>
                  </div>
                  <p className="descrip fs-lg fw-medium text-center">
                    Countries
                  </p>
                  <p>Where We Worked</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default About;
