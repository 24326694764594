import { useEffect } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import "./Product.css";
import AOS from "aos";
import { Container, Row, Col, NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CiBank } from "react-icons/ci";
import Clients from "../../Components/Home/Lifecycles/Clients";
import {
  FaStudiovinari,
  FaWpforms,
  FaBone,
  FaCompressArrowsAlt,
  FaDraftingCompass,
} from "react-icons/fa";
const Product = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <section className="product-expricnce pt-120 pb-120 texture-bg-2">
        <Container>
          <Row>
            <Col lg={12} data-aos="fade-down">
              <div className="text-center pt-120 pb-120">
                <h4 className="display-four ">
                  <span className="tcp-1"> Our Products</span>
                </h4>
                <p>
                  We offer top-quality products designed to meet our clients'
                  needs
                </p>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <div className="product-head bgc-2" data-aos="fade-up">
                <div className="products-icons">
                  <i>
                    <CiBank />
                  </i>
                </div>
                <h5>Banking Solutions for Mercantile</h5>
                <p>
                  We deliver tailored business application solutions for the
                  banking and capital markets industries, including specialized
                  banking solutions for mercantile enterprises.
                </p>
                <Link to="/#">Read More</Link>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <div className="product-head bgc-2" data-aos="fade-up">
                <div className="products-icons">
                  <i>
                    <FaStudiovinari />
                  </i>
                </div>
                <h5>Jewelry Management System</h5>
                <p>
                  We deliver tailored business application solutions for the
                  banking and capital markets industries, including specialized
                  banking solutions for mercantile enterprises.We develop
                  comprehensive systems to automate key jewelry transactions,
                  including purchases, sales, and bill preparation.
                </p>
                <Link to="/#">Read More</Link>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <div className="product-head bgc-2" data-aos="fade-up">
                <div className="products-icons">
                  <i>
                    <FaWpforms />
                  </i>
                </div>
                <h5>POS Billing and Stock Management System</h5>
                <p>
                  We offer an advanced POS billing and stock management solution
                  equipped with features such as tax handling, inventory
                  management, add-ons, multi-store support, detailed reporting,
                  invoicing, and barcode integration.
                </p>
                <Link to="/#">Read More</Link>
              </div>
            </Col>

            <Col lg={4} md={6} sm={12}>
              <div className="product-head bgc-2" data-aos="fade-up">
                <div className="products-icons">
                  <i>
                    <FaBone />
                  </i>
                </div>
                <h5>HRMS</h5>
                <p>
                  Our HRMS software enables enterprises and corporations to
                  seamlessly automate their entire post-recruitment HR functions,
                  from onboarding to final separation.
                </p>
                <Link to="/#">Read More</Link>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <div className="product-head bgc-2" data-aos="fade-up">
                <div className="products-icons">
                  <i>
                    <FaCompressArrowsAlt />
                  </i>
                </div>
                <h5>Asset Maintenance Management System</h5>
                <p>
                  Our Asset Management platform provides complete visibility and
                  control over your assets. It helps you track and manage assets
                  throughout their entire lifecycle.
                </p>
                <Link to="/#">Read More</Link>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <div className="product-head bgc-2" data-aos="fade-up">
                <div className="products-icons">
                  <i>
                    <FaDraftingCompass />
                  </i>
                </div>
                <h5>Project Management System</h5>
                <p>
                  Our Project Management System offers robust tools to plan,
                  execute, and monitor projects efficiently, ensuring timely
                  delivery and optimal resource utilization.
                </p>
                <Link to="/#">Read More</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="product-banner">
        <div className="banner-i">
          <h4>
            Do you have an idea?
          </h4>
          <p>Contact us, and we can turn it into reality for you!</p>
        </div>
        <div className="hero-btn-area d-block d-lg-flex">
          <a className="bttn-1" href="/request-a-quote">Request a Quote
          <span className="icon d-center icon-right">
            <i><FaArrowRightLong /></i></span></a></div>
      </div>
      <Clients />
    </>
  );
};

export default Product;
