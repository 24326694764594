import React from 'react';
import "./Lifecycle.css";
import { BsRocketTakeoff } from "react-icons/bs";
import { FaArrowRightLong } from "react-icons/fa6";
import { BiPhoneCall } from "react-icons/bi";
import { TbBrandSkype } from "react-icons/tb";
import { Container,Row ,Col } from 'react-bootstrap';
import newsletter4 from "../../../assets/images/newsletter4.png"
import { RiMessengerLine } from "react-icons/ri";
import { FaSlack } from "react-icons/fa";
import { Link } from 'react-router-dom';

const ContactNews = () => {
  return (
    <section className="newsletter-section-4 pt-120 pb-0 texture-bg-2">
      <Container>
        <Row>
          <Col lg={5}>
            <div className="text-center text-lg-start" data-aos="fade-up">
              <span className="subheading-border fw-medium mb-4 fs-xl">
                <span className="fs-2xl">
                  <i><BsRocketTakeoff/></i>
                </span>
                Contact Us
              </span>
              <h2 className="hero-title display-four mb-lg-6 mb-4">
                Get in <span className="tcp-1">Touch</span>
              </h2>
              <p>
                We’re excited to hear from you. Whether you have questions, suggestions, or just want to share your thoughts, we’re here to listen and assist.
              </p>
            </div>
            <div className="d-flex flex-wrap align-items-lg-center justify-content-center justify-content-lg-start gap-sm-6 gap-4 py-lg-10 py-sm-8 py-6" data-aos="fade-up">
              <a href="tel:64546565468" className="icon-btn d-center gap-3 ">
                <span className="icon fs-xl fw-bold">
                  <i><BiPhoneCall/></i>
                </span>
                <span className="fw-semibold text-white">+91 731 358 9090</span>
              </a>
              <a href="#" className="icon-btn d-center gap-3 ">
                <span className="icon fs-xl fw-bold">
                  <i><TbBrandSkype/></i>
                </span>
                <span className="fw-semibold text-white">mobileapp_expert</span>
              </a>
            </div>
            <div className="text-center text-lg-start mb-4" data-aos="fade-up">
              <Link to="/contact" className="bttn-1">
                Contact Us
                <span className="icon d-center icon-right">
                  <i><FaArrowRightLong/></i>
                </span>
              </Link>
            </div>
          </Col>

          <Col xxl={6} lg={7}>
            <div className="news-4-banner mb-4">
              <div className="d-flex flex-column flex-sm-row gap-lg-6 gap-4">
                <div className="news-width"data-aos="fade-up">
                  <div className="img-area">
                    <img className="w-100 rounded" 
                    src={newsletter4} 
                    alt="newsletter" />
                  </div>
                </div>

                <div className="d-grid gap-lg-6 gap-sm-4 gap-2 news-width">
                  <a href="#" className="live-chat-item d-block p-6 bgc-3 rounded ms-auto"data-aos="fade-up">
                    <span className="icon-btn mb-2 d-block">
                      <span className="icon fs-xl fw-bold">
                        <i><RiMessengerLine/></i>
                      </span>
                    </span>
                    <span className="fs-five fw-semibold mb-2 d-block">Chat</span>
                    <span className="d-block fw-normal">Live chat with Messenger</span>
                  </a>

                  <a href="#" className="live-chat-item d-block p-6 bgc-3 rounded"data-aos="fade-up">
                    <span className="icon-btn mb-2 d-block">
                      <span className="icon fs-xl fw-bold">
                        <i ><FaSlack/></i>
                      </span>
                    </span>
                    <span className="fs-five fw-semibold mb-2 d-block">Slack</span>
                    <span className="d-block fw-normal">Contact on Slack</span>
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactNews;
